const NAMESAPCE = '.open-window';
const itemSelector = '.open-window';
const eventName = 'click';

export const initialise = () => {
    $(document)
        .off(eventName + NAMESAPCE, itemSelector)
        .on(eventName + NAMESAPCE, itemSelector, (e) => {
            e.preventDefault();
            window.open(
                e.target.href,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
            );
        });
};
