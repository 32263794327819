import { SPA } from '../config';
import { debounce, throttle } from '../helpers/timing';
import { isMobile } from '../helpers/os';

const NAMESPACE = 'featuresTabs';
const eventName = 'click';
const event = `${eventName}.${NAMESPACE}`;
const resizeEvent = `resize.${NAMESPACE}`;

const tab = 'features-tab';
const tabOn = `${tab}--on`;

const getCurrentTab = () => {
    const path = window.location.pathname;
    return $(`.features-tab[href^="${path}"]`);
};

const ARROW_SCROLL_SPEED = 300;

export const initialise = () => {
    const $win = $(window);
    const $document = $(document);
    const $body = $(document.body);
    const $container = $('.features-tabs-container');
    const $scrollArea = $('.features-tabs');
    const $fakeScroll = $('.features-tabs-scroll');
    const $tabs = $(`.${tab}`);
    const $line = $(`.${tab}s-line`);

    if (!$container.length) {
        return;
    }

    const isOnMobile = isMobile();

    const disableDocumentSelect = () => $body.css({ userSelect: 'none' });
    const reEnableDocumentSelect = () => $body.css({ userSelect: '' });

    const updateEdgesOnScroll = () => {
        const left = $scrollArea[0].scrollLeft;
        const scrollWidth = $scrollArea[0].scrollWidth;
        const width = $scrollArea.width();
        const right = scrollWidth - width - left;

        $container
            .attr('data-highlight-left-edge', left > 1 ? 'true' : 'false')
            .attr('data-highlight-right-edge', right > 1 ? 'true' : 'false');
    };

    const updateFakeScrollPosition = () => {
        const { scrollLeft, scrollWidth } = $scrollArea[0];
        const width = $scrollArea.width();
        const ratio = width / scrollWidth;

        $fakeScroll.css({
            width: Math.floor(width * ratio),
            left: Math.floor(scrollLeft * ratio)
        });
    };

    const scrollNavBarTo = (direction) => {
        const scrollWidth = $scrollArea.width();
        const scrollLeft = $scrollArea.scrollLeft();

        const tabOnTheEdge = [...$tabs].find((tab) => {
            const $tab = $(tab);
            const left = $tab.position().left;
            const tabWidth = $tab.outerWidth();
            const gap = (tabWidth - $tab.width()) / 2;

            if (direction === 'next') {
                return (
                    left + tabWidth - gap > scrollWidth &&
                    left - gap < scrollWidth
                );
            } else {
                return left + tabWidth + gap > 0 && left + gap < 0;
            }
        });

        if (!tabOnTheEdge) {
            return;
        }

        const $tabOnTheEdge = $(tabOnTheEdge);
        const left = $tabOnTheEdge.position().left;
        const tabWidth = $tabOnTheEdge.outerWidth();

        let newScrollLeft = scrollLeft;
        if (direction === 'next') {
            newScrollLeft = scrollLeft + left;
        } else {
            newScrollLeft = scrollLeft + left - scrollWidth + tabWidth;
        }

        $scrollArea
            .stop()
            .animate({ scrollLeft: newScrollLeft }, ARROW_SCROLL_SPEED);
    };

    // Scroll current tab to the middle of the container
    const scrollToCurrentTab = () => {
        const containerLeft = $scrollArea.offset().left;
        const currentScrollLeft = $scrollArea[0].scrollLeft;
        const containerWidth = $scrollArea.width();

        const $tab = getCurrentTab();
        const tabWidth = $tab.width();
        const tabLeft = $tab.position().left;

        const newScrollLeft =
            currentScrollLeft + tabLeft - containerWidth / 2 + tabWidth / 2;

        $scrollArea[0].scrollLeft = Math.floor(newScrollLeft);
    };

    scrollToCurrentTab();

    $win.off(resizeEvent).on(resizeEvent, () => {
        window.requestAnimationFrame(() => {
            scrollToCurrentTab();
            updateEdgesOnScroll();
            updateFakeScrollPosition();
        });
    });

    $scrollArea.off('scroll.slider-edge').off('scroll.slider-scroll-spy');

    $scrollArea.on('scroll.slider-edge', () => {
        window.requestAnimationFrame(() => {
            updateEdgesOnScroll();
        });
    });

    const initFakeScrollSpy = () =>
        $scrollArea.on('scroll.slider-scroll-spy', () => {
            window.requestAnimationFrame(() => {
                updateFakeScrollPosition();
            });
        });
    const destroyFakeScrollSpy = () =>
        $scrollArea.off('scroll.slider-scroll-spy');
    initFakeScrollSpy();

    $('.features-tabs-next')
        .off('click')
        .on('click', () => {
            scrollNavBarTo('next');
        });

    $('.features-tabs-prev')
        .off('click')
        .on('click', () => {
            scrollNavBarTo('prev');
        });

    $document
        .off('mousedown', '.features-tabs-scroll')
        .on('mousedown', '.features-tabs-scroll', () => {
            const containerLeft = $container.offset().left;
            const scrollAreaLeft = $scrollArea.offset().left;
            const scrollAreaWidth = $scrollArea.width();
            const fakeScrollLeft = $fakeScroll.offset().left;
            const fakeScrollWidth = $fakeScroll.width();

            const ratio = scrollAreaWidth / fakeScrollWidth;

            const maxLeft = scrollAreaWidth - fakeScrollWidth;

            destroyFakeScrollSpy();
            $fakeScroll.attr('data-active', 'true');
            disableDocumentSelect();

            let startMovePoint = null;
            let startLeftPosition = null;
            $document
                .on('mousemove.slider', (event) => {
                    const mouseLeft = isOnMobile
                        ? event.originalEvent.touches[0].clientX
                        : event.clientX;

                    startLeftPosition =
                        startLeftPosition === null
                            ? parseFloat($fakeScroll.css('left'))
                            : startLeftPosition;
                    startMovePoint =
                        startMovePoint === null ? mouseLeft : startMovePoint;

                    const diff = mouseLeft - startMovePoint;
                    let newPosition = startLeftPosition + diff;
                    if (newPosition < 0) {
                        newPosition = 0;
                    } else if (newPosition > maxLeft) {
                        newPosition = maxLeft;
                    }
                    $fakeScroll.css({ left: newPosition });

                    const newScrollLeft = newPosition;
                    $scrollArea[0].scrollLeft = newPosition * ratio;
                })
                .on('mouseup.slider', () => {
                    startMovePoint = null;
                    $fakeScroll.attr('data-active', 'false');
                    $document.off('mousemove.slider').off('mouseup.slider');

                    initFakeScrollSpy();
                    reEnableDocumentSelect();
                });
        });
};
