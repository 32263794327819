const { detect } = require('detect-browser');
import cookie from "../helpers/cookie";
import { getUrlParam } from "../helpers/url";

export const initialise = () => {
    const os = (function() {
        const browser = detect();
        if (!browser) {
            return null;
        } else if (browser.os.substr(0, 7) === "Windows") {
            return "win";
        } else if (browser.os === "iOS" || browser.os === "Mac OS") {
            return "mac";
        } else {
            return null;
        }
    })();

    const campaign = cookie.get('campaign');
    const source = cookie.get('source');
    const couponCode = getUrlParam('coupon') || cookie.get('coupon');
    const email = getUrlParam('email')

    const flags = {};
    if (os) flags.os = os;
    if (campaign) flags.campaign = campaign;
    if (source) flags.source = source;
    if (couponCode) flags.couponCode = couponCode;
    if (email) flags.email = email;

    window.clientSideStoreFlags = flags;
}
