import { os, isMobile } from '../helpers/os';

const focus = 'focus click';
const blur = 'blur';

const downloadNotStarted = '.download-not-started a';

const resetForm = ($container) => {
    $container.find('[data-form-status]').attr('data-form-status', '');
    const $form = $container.find('form');
    if ($form.length) {
        $form[0].reset();
    }
};

export const initialise = () => {
    const platform = window.navigator.platform.toLowerCase();
    const onMobile = platform.indexOf('win') < 0 && platform.indexOf('mac') < 0;

    const $doc = $(document);

    if (onMobile) {
        const $modal = $('#mobile-download');
        $modal.off('hidden.bs.modal').on('hidden.bs.modal', () => {
            setTimeout(() => {
                resetForm($modal);
            }, 0);
        });
    }

    $doc.off('click', downloadNotStarted).on(
        'click',
        downloadNotStarted,
        (e) => {
            e.preventDefault();
            const detectedOs = os();
            const href = $(`meta[name="download-${detectedOs}"]`).attr(
                'content'
            );
            if (href) {
                window.location.href = href;
            }
        }
    );

    // Download trigger on incompatible device
    const downloadButtons = '.action-download';
    $doc.off('click', downloadButtons).on('click', downloadButtons, (e) => {
        if (onMobile) {
            e.preventDefault();
            $('#mobile-download').modal('show');
        }
    });

    // start animation of download browser arrow
    setTimeout(() => {
        $('.download-arrow[data-animate="false"]').attr('data-animate', 'true');
    }, 2000);
};
