import './polyfills';

import LazyLoad from 'vanilla-lazyload';

import { init, toInit } from './app/init';

// Modules
import { initialise as responsiveSize } from './modules/responsive-size';
import { initialise as animations } from './modules/animations';

import { initialise as testimonials } from './modules/testimonials';
import { initialise as socialButtons } from './modules/social';
import { initialise as navigation } from './modules/navigation';

import { initialise as initForms } from './modules/forms';
import { initialise as reinitbootstrap } from './modules/bootstrap-reinit';
import { initialise as featureTabs } from './modules/features-tabs';
import { initialise as adwordsTracking } from './modules/tracking';
import { initialise as modalVideoAutoplay } from './modules/modal-video';
import { initialise as modalDialogs } from './modules/modal-dialogs';
import { initialise as handleDownload } from './modules/download';
import { initialise as licenseRetrieval } from './modules/license-retrieval';
import { initialise as faq } from './modules/faq';
import { initialise as banner } from './modules/banner';
import { initialise as omnibar } from './modules/omnibar';
import { initialise as ajaxForms } from './modules/ajax-form';

import { initialise as browserDetection } from './modules/detect-browser';

import { initialise as store } from './modules/store';

import { updateCouponCookie } from './modules/coupon';

import './modules/store/thank-you';

import './modules/stopExecutionOnTimeout';

toInit([
    responsiveSize,
    browserDetection,
    testimonials,
    socialButtons,
    navigation,

    initForms,
    reinitbootstrap,
    featureTabs,
    adwordsTracking,
    modalVideoAutoplay,
    handleDownload,
    licenseRetrieval,
    faq,
    banner,
    omnibar,
    ajaxForms,
    animations,
    updateCouponCookie,
    modalDialogs,
    store
]);

init();

/*
const myLazyLoad = new LazyLoad({
    elements_selector: '.lazy-image',
    use_native: true
});
*/
// once content updated execute `myLazyLoad.update();`
