import { getUrlParam } from '../../helpers/url';
import cookie from '../../helpers/cookie';
import { waitFor } from '../../helpers/waitFor';
import * as language from '../../helpers/language';

export const getCoupon = () => getUrlParam('coupon') || cookie.get('coupon');
export const getEmail = () => getUrlParam('email');
export const getProductEl = (key) =>
    document.querySelector(`.store-packages-item[data-product-key="${key}"]`);
export const isFunction = (what) => typeof what === 'function';

const waitForGACondition = () => 'ga' in window && !!ga.getAll;

export const dataLayerPush = (eventObject) => {
    window.dataLayer = window.dataLayer || [];

    if (window.dataLayer && isFunction(window.dataLayer.push)) {
        window.dataLayer.push(eventObject);
    }
};

export const trackEvent = ({ action = 'View', label }) => {
    waitFor(waitForGACondition, () => {
        const tracker = ga.getAll()[0];
        if (tracker) {
            tracker.send('event', 'Store', action, label);
        }
    });
};

export const getStoreData = (cfg = {}) => {
    const lang = language.getCurrent().substr(0,2);
    
    let data = {
        provider: cfg.provider,
        coupon: getCoupon(),
        email: getEmail(),
        country: getUrlParam('country'),
        product_ids: cfg.productIds.join(',')
    };

    if (getUrlParam('inapp')) {
        data.inapp = 'true';
    }

    data = {
        ...data,
        ...cfg.data
    };

    $.ajax({
        type: 'get',
        url: data.email ? `/${lang}/api/store` : `/${lang}/api/cache/store`,
        data,
        cache: false,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'X-Alt-Referer': document.referrer
        },
        success({ data } = {}) {
            if (!data) {
                return;
            }

            if (cfg.onSuccess) {
                cfg.onSuccess(data);
            }
        }
    });
};
