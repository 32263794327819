export const COOKIE_PREFIX = '__ddna__';
export const COUPON_COOKIE_TTL = 1;

export const STORRAGE_PREFIX = '__ddna__';

export const OS = {
    WINDOWS: 'windows',
    MAC: 'mac'
};

export const SPA = false;

export const REGEXP = {
    EMAIL: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
};

function getAPIHostName() {
    if ( window.location.hostname === 'staging.imazing.com' ) {
        return 'api-staging.imazing.com';
    } else if ( window.location.hostname === 'preview.imazing.com' ) {
        return 'api-preview.imazing.com';
    } else {
        return 'api.imazing.com';
    }
}

export const API = {
    RESTORE_LICENSE: 'https://' + getAPIHostName() + '/v1/licenses/retrieve_license_code'
}

export const MAILCHIMP = {
    U: '9820780b9ca506ece36277a8c',
    ACTION_DOMAIN: 'imazing.us4.list-manage.com',
    LIST_ID: {
        DEFAULT: '112355e150',
        BETA: '067ef48a36'
    }
};

// TjRus personal mailchimp config, just for test
// export const MAILCHIMP = {
//     U: '15d0fb6b01bc1a095df1b5f64',
//     ACTION_DOMAIN: 'tjrus.us7.list-manage.com',
//     LIST_ID: {
//         DEFAULT: '446431b672',
//         BETA: '446431b672'
//     }
// };
