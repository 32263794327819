import { STORRAGE_PREFIX } from '../config';

export default class Storage {
    constructor(options = {}) {
        const { type = 'session' } = options;

        this.store =
            type === 'session' ? window.sessionStorage : window.localStorage;
    }

    get(key) {
        return this.store.getItem(`${STORRAGE_PREFIX}.${key}`);
    }

    set(key, value) {
        return this.store.setItem(`${STORRAGE_PREFIX}.${key}`, value);
    }

    remove(key) {
        return this.store.removeItem(`${STORRAGE_PREFIX}.${key}`);
    }
}
