import { getSizeShortname } from './responsive-size';
const NAMESPACE = 'faq';
const eventName = 'click';
const event = `${eventName}.${NAMESPACE}`;
const resizeEvent = `resize.${NAMESPACE}`;

const SEARCH_DELAY = 500;

export const initialise = () => {
    const $win = $(window);
    const $doc = $(document);

    const $html = $('html');

    // Question toggle expand for XS sizes
    const questionsClickSelector = '.block-faq .block-faq-question a';
    const questionSelector = '.block-faq .block-faq-question';

    $doc.off(event).on(event, questionsClickSelector, (e) => {
        e.preventDefault();
        const size = getSizeShortname();
        if (size !== 'xs') {
            return;
        }
        const $answer = $(e.currentTarget)
            .closest(questionSelector)
            .next();
        $answer.stop().slideToggle();
    });

    // FAQ layout adapting / hijacking for XS sizes
    const $answers = $('.block-faq .block-faq-answer');
    const $tabs = $('.faq-tabs');
    const $pills = $('.faq-nav-pill');
    const activeTab = 'in active';

    let switchedTo;
    const onResize = () => {
        const size = getSizeShortname();
        const changeFromOrToXS = switchedTo === 'xs' || size === 'xs';
        if (switchedTo === size || !changeFromOrToXS) {
            return;
        }
        switchedTo = size;

        if (size === 'xs') {
            $answers.hide();
            $tabs.addClass(activeTab);
            $pills.removeClass('active');
        } else {
            $answers.show();
            $tabs.not(':first').removeClass(activeTab);
            $pills.first().addClass('active');
        }
    };

    $win.off(resizeEvent).on(resizeEvent, onResize);

    setTimeout(onResize, 0);

    // Faq instant search
    const $faqBlock = $('.block-faq');
    const $resultsBlock = $('.block-faq-results');
    const $results = $('#block-faq-results');
    const $noResults = $('.block-faq-no-results');

    const $count = $('.faq-search-form-results-count');
    const $magnifier = $('.faq-search-form-magnifier');

    const close = '#faq-search-form .close';
    const $close = $(close);
    const searchField = '#faq-search-form [name="s"]';
    const $searchField = $(searchField);
    let typingTimer;

    $('.block-faq .block-faq-qa').each((index, item) =>
        $(item)
            .clone()
            .appendTo($results)
    );

    const $context = $('.block-faq-results .block-faq-qa');

    const renderResults = (search) => {
        $context.show().unmark();
        $noResults.hide();

        if (search) {
            $context.each((index, item) => {
                const $item = $(item);
                if (item.innerText.toLowerCase().indexOf(search) !== -1) {
                    $item.show();
                } else {
                    $item.hide();
                }
            });

            $resultsBlock.show();
            const $visible = $('#block-faq-results .block-faq-qa:visible');
            $count.show().text($visible.length);
            $magnifier.hide();

            if (!$visible.length) {
                $noResults.show();
                $faqBlock.show();
            } else {
                $faqBlock.hide();
            }

            $context.mark(search, {
                accuracy: 'partially',
                separateWordSearch: false
            });
        }
    };
    const hideResults = () => {
        $faqBlock.show();
        $resultsBlock.hide();
        $count.hide();
        $magnifier.show();
    };

    const doSearch = (e) => {
        const search = e.target.value.toLowerCase();

        clearTimeout(typingTimer);
        if (search.length > 0) {
            $close.show();
        } else {
            $close.hide();
        }
        if (search.length >= 3) {
            if (e.instant === true) {
                renderResults(search);
            } else {
                typingTimer = setTimeout(() => {
                    renderResults(search);
                }, SEARCH_DELAY);
            }
        } else {
            hideResults();
        }
    };

    // Handles focus on field input by clicking search magnifier
    $doc.off('click', '.faq-search-form-magnifier').on(
        'click',
        '.faq-search-form-magnifier',
        () => $searchField.focus()
    );

    // Handles click on search cross
    $doc.off('click', close).on('click', close, (e) => {
        const urlSearch = window.location.search;
        if (
            urlSearch.indexOf('?s=') !== -1 ||
            urlSearch.indexOf('&s=') !== -1
        ) {
            // drop search
        } else {
            e.preventDefault();
            $searchField.val('');

            doSearch({ target: { value: '' } });
        }
    });

    // Handles changes of search field
    $doc.off('input', searchField).on('input', searchField, doSearch);

    if ($searchField.length) {
        doSearch({ target: $searchField.get(0), instant: true });
    }
};
