const targetSelector = '.header-fixed';
const targetAttribute = 'data-frame';
const maxFrame = 56;
const maxScroll = 91;

export const initialise = () => {
    let headerFixed;
    let main;

    const updateScrollAttribute = () => {
        // The Store has a different scroll behaviour than other pages so
        // we have to check 2 different sources to get vertical offset.
        const scrollPos = main.scrollTop || window.scrollY;
        const frame = Math.min(
            Math.round((scrollPos / maxScroll) * maxFrame),
            maxFrame
        );
        headerFixed.setAttribute(targetAttribute, frame);
    };

    // Wait for <main> element to be loaded then initialise scroll spy.
    const handle = setInterval(() => {
        headerFixed = document.querySelector(targetSelector);
        main = document.querySelector('main');
        if (!headerFixed || !main) return;

        clearInterval(handle);

        // Support for Store and other pages.
        main.addEventListener('scroll', (e) => updateScrollAttribute());
        document.addEventListener('scroll', (e) => updateScrollAttribute());

        updateScrollAttribute();
    }, 50);
};
