export const getUrlParam = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const queryString = (data) => {
    return Object.keys(data)
        .map((key) =>
            typeof data[key] !== 'undefined' ? `${key}=${data[key]}` : null
        )
        .filter((i) => i)
        .join('&');
};

export const goto = (url) => {
    window.location.href = url;
};
