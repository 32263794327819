import { getUrlParam } from '../helpers/url';
import cookie from '../helpers/cookie';

const TIME = 60;
const ADWORDS = 'adwords';

export const initialise = () => {
    // AdWords parameters
    const gclid = getUrlParam('gclid');

    // Google Analytics parameters
    const utm_source = getUrlParam('utm_source');
    const utm_campaign = getUrlParam('utm_campaign');

    // Custom parameters
    const ref = getUrlParam('ref');
    const source = getUrlParam('source');
    const campaign = getUrlParam('campaign');

    // Google AdWords
    if (gclid) {
        cookie.set('source', ADWORDS, TIME);
        cookie.set('campaign', ADWORDS, TIME);
    }

    // Source
    if (ref) {
        cookie.set('source', ref, TIME);
    }
    if (source) {
        cookie.set('source', source, TIME);
    }
    if (utm_source) {
        cookie.set('source', utm_source, TIME);
    }

    // Campaign
    if (campaign) {
        cookie.set('campaign', campaign, TIME);
    }
    if (utm_campaign) {
        cookie.set('campaign', utm_campaign, TIME);
    }

    // Paddle Affiliation
    const pToken = getUrlParam('p_tok');
    if (pToken) {
        setAffiliate(pToken);
    }
};

const setAffiliate = (paddleToken) => {
    if (typeof paddleToken !== 'undefined' && paddleToken) {
        const affiliateCookieData = {
            token: paddleToken
        };

        cookie.strictSet(
            'paddlejs_campaign_affiliate',
            JSON.stringify(affiliateCookieData),
            30
        );
    }
};
