import MutationObserver from 'mutation-observer';

const { keys } = Object;

const $ = (selector) => document.querySelectorAll(selector);
const inDom = (el) => document.documentElement.contains(el);

const actionTypes = {
    childList: (mutation) => {
        if (mutation.addedNodes.length) {
            // attached
            checkAttached();
        }

        if (mutation.removedNodes.length) {
            // removed
            checkRemoved();
        }

        // Detect direct change
        SPY.filter(
            (spyInstance) =>
                spyInstance.elements.indexOf(mutation.target) !== -1
        ).forEach((spyInstance) => spyInstance.onChange(mutation.target));
    },

    attributes: (mutation) => {
        SPY.filter(
            (spyInstance) =>
                spyInstance.elements.indexOf(mutation.target) !== -1
        ).forEach((spyInstance) => spyInstance.onChange(mutation.target));
    },

    characterData: (mutation) => {}
};

function checkAttached() {
    SPY.forEach((spyInstance) => {
        const foundDomElements = Array.from($(spyInstance.selector));
        if (foundDomElements.length) {
            foundDomElements.forEach((el) =>
                spyInstance.onAttach.call(spyInstance, el)
            );
        }
    });
}

function checkRemoved() {
    SPY.forEach((spyInstance) => {
        spyInstance.elements.forEach((el) => {
            if (!inDom(el)) {
                spyInstance.onRemove.call(spyInstance, el);
            }
        });
    });
}

const observer = new MutationObserver((mutations) => {
    // For the sake of...observation...let's output the mutation to console to see how this all works
    mutations.forEach((mutation) => {
        const action = actionTypes[mutation.type];
        if (action) {
            action(mutation);
        }
    });
});

// Notify me of everything!
const observerConfig = {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true,
    attributeOldValue: true,
    characterDataOldValue: true
    // attributeFilter: []
};

// Node, config
// In this case we'll listen to all changes to body and child nodes
const targetNode = document.body;
observer.observe(targetNode, observerConfig);

const SPY = [];

window.__SPY = SPY;

export default class DomSpy {
    constructor(selector, cfg) {
        this.selector = selector;
        this.elements = [];
        this.cb = {};
        SPY.push(this);

        this.cb.onAttach = cfg.attached;
        this.cb.onRemove = cfg.removed;
        // this.cb.onCharacterData = cfg.removed;
        this.cb.onChange = cfg.changed;

        checkAttached();
    }

    destroy() {
        SPY.splice(SPY.indexOf(this), 1);
    }

    onAttach(el) {
        if (this.elements.indexOf(el) !== -1) {
            return;
        }

        if (this.cb.onAttach) {
            this.cb.onAttach.call(null, el);
        }
        this.elements.push(el);
    }

    onRemove(el) {
        const index = this.elements.indexOf(el);
        if (index === -1) {
            return;
        }

        if (this.cb.onRemove) {
            this.cb.onRemove.call(null, el);
        }
        this.elements.splice(index, 1);
    }

    onChange(el) {
        if (this.cb.onChange) {
            this.cb.onChange.call(null, el);
        }
    }
}
