import Storage from './storage';

const int = (str) => parseInt(str, 10);

const DEFAULT_CACHE_TIME = 5 * 60 * 1000; // 5 min
export const cachedGet = (options) => {
    const {
        url = '',
        fetchOptions = {},
        cacheTime = DEFAULT_CACHE_TIME,
        key,
        storageType = 'session'
    } = options;

    if (!key) {
        throw 'Key is required for cached get request';
    }

    const state = new Storage({ type: storageType });
    const cachedTime = int(state.get(`${key}.time`)) || 0;
    const cachedResult = state.get(`${key}.res`);
    const cachedForUrl = state.get(`${key}.url`);

    if (
        cachedTime + cacheTime > Date.now() &&
        cachedForUrl === url &&
        cachedResult
    ) {
        const res = fetchedResultMock(cachedResult);
        return Promise.resolve(res);
    }

    return fetch(url, fetchOptions)
        .then((res) => res.json())
        .then((data) => {
            try {
                state.set(`${key}.time`, Date.now());
                state.set(`${key}.res`, JSON.stringify(data));
                state.set(`${key}.url`, url);
            } catch (e) {
                // means we can't set storrage items
                // we'll just skip them then
            }
            return fetchedResultMock(data);
        });
};

const fetchedResultMock = (result) => ({
    json: () => {
        try {
            return JSON.parse(result);
        } catch (e) {
            return result;
        }
    }
});
