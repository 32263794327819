export const startCaptcha = (e, callback) => {
    const RETRY_DELAY = 250; // delay in milliseconds
    const MAX_RETRIES = 40;

    // counter to manage retries
    let count = 0;

    const _start = () => {
        // if max retries count is reached, stop retry and display an error message
        if (++count >= MAX_RETRIES) {
            console.log("Error: reCAPTCHA could not be loaded. Unable to submit the form.");
            return;
        }

        // if reCAPTCHA is not loaded yet, retry after a delay
        if (!window.__RECAPTCHA_IS_LOADED) {       
            console.log("reCAPTCHA is not loaded, retrying... #" + count);
            setTimeout(() => _start(), RETRY_DELAY);
            return;
        }

        // start reCAPTCHA
        _startCaptcha(e, callback);
    }

    _start(e, callback);
};

/** Renders and executes reCAPTCHA */
const _startCaptcha = (e, callback) => {
    const $form = $(e.target);
    const $recaptcha = $form.find('[data-recaptcha]');

    if (!$recaptcha.length) {
        callback();
        return;
    }
    e.preventDefault();

    const widgetId =
        $recaptcha.attr('data-widget-id') ||
        grecaptcha.render($recaptcha[0], {
            callback: (token) => {
                callback(token);
                grecaptcha.reset();
            }
        });
    $recaptcha.attr('data-widget-id', widgetId);
    grecaptcha.execute(widgetId);
};
