import { MAILCHIMP } from '../config';
import { os } from './os';

const { U, ACTION_DOMAIN, LIST_ID } = MAILCHIMP;

export const data = (data, listType = '') => {
    listType = listType.toUpperCase();
    const listId = LIST_ID[listType] || LIST_ID.DEFAULT;
    const action = `//${ACTION_DOMAIN}/subscribe/post-json?u=${U}&id=${listId}&c=?`;
    const detectedOS = os() === 'windows' ? 'win' : 'mac';
    const htmlEl = document.querySelector('html');
    const formData = {};

    const fields = [
        'EMAIL',
        'FNAME',
        'LNAME',
        'TYPE',
        'PRODUCT',
        'PRODUCTVER',
        'OS',
        'SOURCE',
        'CAMPAIGN',
        'COUNTRY'
    ];
    fields.forEach((key) => {
        if (data.hasOwnProperty(key.toLowerCase())) {
            formData[key] = data[key.toLowerCase()];
        }
    });

    if (data.hasOwnProperty('optinso')) {
        formData.OPTINSO =
            data.optinso === true || data.optinso === 1 || data.optinso === '1'
                ? 1
                : 0;
    }

    if (formData.OS == '') {
        formData.OS = detectedOS;
    }

    formData.LANG = htmlEl.getAttribute('lang');

    formData[`b_${U}_${listId}`] = '';
    return { action, formData };
};

export const subscribe = (cfg) => {
    const mailchimpData = data(cfg.data);

    const ajaxConfig = {
        ...cfg,
        type: 'post',
        url: mailchimpData.action,
        data: mailchimpData.formData,
        cache: false,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8'
    };
    $.ajax(ajaxConfig);
};

export const backendSubscribe = (data, config = {}) => {
    const headers = {};
    const connHash = $('#x-connection-dna').val();
    if (connHash) {
        headers['X-Connection-DNA'] = connHash;
    }
    const ajaxConfig = {
        type: 'POST',
        url: '/api/subscribe',
        data,
        cache: false,
        headers: headers,
        ...config
    };
    $.ajax(ajaxConfig);
};
