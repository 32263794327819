import { toInit } from '../app/init';
import { isMobile } from '../helpers/os';

const NAMESPACE = 'testimonials';
const eventName = isMobile() ? 'touchstart' : 'click';
const event = `${eventName}.${NAMESPACE}`;
const resizeEvent = `resize.${NAMESPACE}`;

const slider = {
    currentSlide: 0,
    itemsPerView: 3,
    size: 'md',

    sm: 768,
    xs: 600,
    config: {
        md: 3,
        sm: 2,
        xs: 1
    },

    init: () => {
        slider.$container = $('.testimonials-slider');
        slider.$el = $('.testimonials');
        slider.$prev = $('.testimonials-slider-prev');
        slider.$next = $('.testimonials-slider-next');
        slider.$items = $('.testimonial-item');
        slider.itemsCount = slider.$items.length;
        slider.lastSlideIndex = slider.itemsCount - 1;

        slider.refresh();
    },
    initEvents: () => {
        slider.$prev.off(event).on(event, () => {
            if (slider.currentSlide > 0) {
                slider.goTo(slider.currentSlide - slider.config[slider.size]);
            }
        });

        slider.$next.off(event).on(event, () => {
            if (slider.currentSlide < slider.itemsCount - slider.itemsPerView) {
                slider.goTo(slider.currentSlide + slider.config[slider.size]);
            }
        });
    },

    refresh: () => {
        slider.resize();

        slider.itemsPerView = slider.config[slider.size];

        slider.step = 100 / slider.itemsPerView;

        slider.$el.css({
            width: `calc( ${slider.itemsCount} * 100% / ${
                slider.itemsPerView
            } )`
        });

        slider.initEvents();
        slider.goTo(0);
    },

    resize: () => {
        const w = $(window).width();

        if (w > slider.sm) {
            slider.size = 'md';
        } else if (w > slider.xs) {
            slider.size = 'sm';
        } else {
            slider.size = 'xs';
        }
    },

    goTo: (slideIndex) => {
        if (slideIndex < 0) {
            slideIndex = 0;
        }

        if (slideIndex >= slider.itemsCount - slider.itemsPerView) {
            slideIndex = slider.itemsCount - slider.itemsPerView;
        }

        const direction = $('html').attr('dir') === 'rtl' ? 'rtl' : 'ltr';

        slider.currentSlide = slideIndex;
        const directionMargin =
            direction === 'ltr' ? 'margin-left' : 'margin-right';

        slider.$el.css({
            [directionMargin]: `${-1 * slider.step * slideIndex}%`
        });

        const noPrev = slideIndex === 0;
        const noNext = slideIndex === slider.itemsCount - slider.itemsPerView;

        if (noPrev) {
            slider.$prev.attr('data-disabled', 'true');
        } else {
            slider.$prev.removeAttr('data-disabled');
        }
        if (noNext) {
            slider.$next.attr('data-disabled', 'true');
        } else {
            slider.$next.removeAttr('data-disabled');
        }

        if (noPrev && noNext) {
            slider.$prev.addClass('hidden');
            slider.$next.addClass('hidden');
        } else {
            slider.$prev.removeClass('hidden');
            slider.$next.removeClass('hidden');
        }
    }
};

export const initialise = () => {
    slider.init();
    $(window)
        .off(resizeEvent)
        .on(resizeEvent, () => {
            const currentSize = slider.size;
            slider.resize();
            if (currentSize !== slider.size) {
                slider.refresh();
            }
        });
};
