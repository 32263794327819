require('core-js/modules/es6.symbol');
require('core-js/modules/es6.object.assign');
require('core-js/modules/es6.object.keys');
require('core-js/modules/es6.array.iterator');
require('core-js/modules/es6.array.find');
require('core-js/modules/es6.array.from');
require('core-js/modules/es6.string.starts-with');
require('core-js/modules/es6.string.ends-with');
require('core-js/modules/es6.string.includes');
require('core-js/modules/es7.array.includes');
require('core-js/modules/es7.object.values');
require('core-js/modules/es6.promise');
require('whatwg-fetch');
