export const debounce = (func, wait, immediate) => {
    let timeout;
    return (...args) => {
        const later = () => {
            timeout = null;
            if (!immediate) {
                func(...args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func(...args);
        }
    };
};

export const throttle = (fn, threshhold = 250) => {
    let last;
    let deferTimer;
    return (...args) => {
        const now = +new Date();
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(() => {
                last = now;
                fn(...args);
            }, threshhold);
        } else {
            last = now;
            fn(...args);
        }
    };
};
