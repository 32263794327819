import cookie from '../helpers/cookie';
import { getUrlParam, queryString, goto } from '../helpers/url';
import * as language from '../helpers/language';
import { getSavedCoupon } from './coupon';
import { cachedGet } from '../helpers/fetch';

const event = `click.banner`;
const cookieName = 'banner_';

const container = '.banners-container';
const bannerCloseSelector = '.close';

const closeBanner = (key) => cookie.set(cookieName + key, 'closed');

const getBannerInfo = () => {
    const lang = language.getCurrent().substr(0,2);
    const url = `/${lang}/api/cache/store/banner`;
    const params = {};
    const coupon = getUrlParam('coupon');
    const savedCoupon = getSavedCoupon();

    if (coupon) {
        params.priority = 'coupon';
    }

    if(coupon || savedCoupon)
    {
        params.coupon = coupon || savedCoupon;
    }
    
    const urlWithParams = Object.keys(params).length > 0 ? `${url}?${queryString(params)}` : url;

    return cachedGet({
        url: urlWithParams,
        key: 'banner',
        storageType: 'session'
    }).then((res) => res.json());
};

const showBanner = () => {
    const $container = $(container);
    $container.removeClass('hidden');
    $container.offset();
    setTimeout(() => $container.addClass('show'), 20);

    // click listener
    $container.off(event).on(event, (e) => {
        const $target = $(e.target);
        if ($target.closest(bannerCloseSelector).length === 1) {
            e.preventDefault();
            e.stopPropagation();

            const $banner = $target.closest('.banner');
            const key = $banner.attr('data-key');

            closeBanner(key);

            $container.removeClass('show');
            setTimeout(() => $container.addClass('hidden'), 600);
        } else {
            goto($container.find('[data-action]').attr('data-action'));
        }
    });
};

export const initialise = () => {
    const $container = $(container);

    if (!$container.length) {
        return;
    }

    getBannerInfo().then(({ data = {}, error }) => {
        const { html, key } = data;

        const bannerWasClosed = cookie.get(`${cookieName}${key}`) === 'closed';
        if (bannerWasClosed) {
            return;
        }

        $container.html(html);

        showBanner();
    });
};
