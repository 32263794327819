import { COOKIE_PREFIX } from '../config';

const cookie = {
    /**
     * Creates or sets cookie value
     * @param name cookie name
     * @param value new cookie value
     * @param days expiration time
     */
    set(name, value, days) {
        cookie.strictSet(cookie.name(name), value, days);
    },

    /**
     * Creates or sets cookie value without naming prefix
     * @param name cookie name
     * @param value new cookie value
     * @param days expiration time
     */
    strictSet(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toGMTString()}`;
        } else {
            expires = '; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        }
        document.cookie = `${name}=${value}${expires}; path=/`;
    },

    /**
     * Returns cookie value by name
     * @param name cookie name
     * @returns {string}
     */
    get(name) {
        return cookie.strictGet(cookie.name(name));
    },

    /**
     * Returns cookie value by non-prefixed name
     * @param name cookie name
     * @returns {string}
     */
    strictGet(name) {
        const regexp = new RegExp(
            '(?:^|; )' +
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                '=([^;]*)'
        );
        const matches = document.cookie.match(regexp);
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    /**
     * Removes cookie
     * @param name cookie to remove
     */
    remove(name) {
        cookie.set(name, '', -1);
    },

    /**
     * get prefixed (scoped) cookie name
     * @param name
     */
    name(name) {
        return name.indexOf(COOKIE_PREFIX) !== 0
            ? `${COOKIE_PREFIX}${name}`
            : name;
    }
};

export default cookie;
