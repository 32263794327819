import DomSpy from '../package/dom-spy';

import { os, isMobile } from '../helpers/os';

const focus = 'focus click';
const blur = 'blur';

export const openModal = (selector) => {
    const $modal = $(selector);
    if (!$modal.length) {
        return;
    }

    $modal.modal('show');
};

const resetModal = ($modal) => {
    // reset form
    const $form = $modal.find('form');
    $form.each((index, el) => {
        const $el = $(el);
        el.reset();
        $el.find('.has-error').removeClass('has-error');
        $el.removeClass('invalid');
    });

    // reset form status
    $modal.find('[data-form-status]').attr('data-form-status', '');
};

export const initialise = () => {
    const onMobile = isMobile();
    const $modalDialogs = $('.generic-modal-dialog');
    const $doc = $(document);

    $modalDialogs.each((index) => {
        const $modal = $modalDialogs.eq(index);
        const {
            autoCloseTime,
            autoShow,
            onMobile: shouldShowOnMobile,
            autoCloseOnSelector,
            autoCloseOnAttribute,
            autoCloseOnValue
        } = $modal.data();

        // Modal autoclose features
        if (autoCloseOnSelector && autoCloseOnAttribute && autoCloseOnValue) {
            let autoCloseSpy;
            let autoCloseTimeout = null;
            $modal
                .on('show.bs.modal', () => {
                    // This is custom solution for autoclose when form receives the "success" status
                    autoCloseSpy = new DomSpy(autoCloseOnSelector, {
                        changed(el) {
                            const attr = el.dataset[autoCloseOnAttribute];
                            if (
                                autoCloseTimeout === null &&
                                attr === autoCloseOnValue
                            ) {
                                autoCloseTimeout = setTimeout(() => {
                                    $modal.modal('hide');
                                }, autoCloseTime || 0);
                            }
                        }
                    });
                })
                .on('hide.bs.modal', () => {
                    autoCloseSpy.destroy();
                    autoCloseSpy = null;
                    clearTimeout(autoCloseTimeout);
                    autoCloseTimeout = null;

                    resetModal($modal);
                });
        }

        if (!shouldShowOnMobile && onMobile) {
            return;
        }

        if (autoShow === true) {
            $modal.modal('show');
        }
        if (autoShow > 0) {
            setTimeout(() => {
                $modal.modal('show');
            }, autoShow);
        }
    });
};
