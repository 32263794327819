const itemSelector = '.modal-video';
const NAMESAPCE = '.modalVideo';
const eventName = 'click';

const id = 'video-modal';

const video = {
    tpl: {
        iframe: (key) =>
            `<iframe src="https://www.youtube.com/embed/${key}?autoplay=1" frameborder="0" allowfullscreen></iframe>`,
        modal: (key) => {
            return `<div id="${id}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                ${video.tpl.iframe(key)}
            </div>
        </div>
    </div>
</div>`;
        }
    }
};

export const initialise = () => {
    $(document)
        .off(eventName + NAMESAPCE, itemSelector)
        .on(eventName + NAMESAPCE, itemSelector, (e) => {
            e.preventDefault();

            const href = $(e.currentTarget).attr('href');

            let params = {};
            href.split('?')[1]
                .split('&')
                .forEach((getGroupStr) => {
                    const [name = '', value = ''] = getGroupStr.split('=');
                    params[name] = value;
                });
            const youtubeKey = params.v;

            $(document.body).append(video.tpl.modal(youtubeKey));

            // Show the dialog
            const $modal = $(`#${id}`);
            $modal.modal('show');

            $modal.off('hidden.bs.modal').on('hidden.bs.modal', () => {
                setTimeout(() => {
                    $modal.remove();
                }, 0);
            });
        });
};
