import { os } from '../helpers/os';
import { goto } from '../helpers/url';
import { startCaptcha } from '../helpers/recaptcha';
import * as mailchimp from '../helpers/mailchimp';

import { REGEXP } from '../config';

const formsSelector = 'form[data-ajax]';
const formInput = `${formsSelector} input`;
const ajaxAttr = 'data-ajax';
const statusAttr = 'data-form-status';
const valuesAttr = 'data-field-value';

const formGroup = '.form-group';
const submitEvent = 'submit';
const hasError = 'has-error';

const invalidClass = 'invalid';
const submittingClass = 'submitting';

const skipFormDataTypes = ['submit', 'reset', 'button'];

const showErrors = ($form, errors, config = {}) => {
    for (let name in errors) {
        $form.find(`[name="${name}"]`).closest(formGroup).addClass(hasError);
    }
    if ($form && config.noShake !== true) {
        $form.removeClass(invalidClass);
        $form.width();
        $form.addClass(invalidClass);
    }
};

const updateShowcases = ($form, data = {}) => {
    const $values = $form.closest(`[${statusAttr}]`).find(`[${valuesAttr}]`);
    $values.each((index, item) => {
        const $item = $(item);
        $item.text(data[$item.attr(valuesAttr)]);
    });
};

const updateStatus = ($form, status, formData) => {
    $form.closest(`[${statusAttr}]`).attr(statusAttr, status);

    let resultUrl = $form.attr(`data-goto-${status}`);
    if (resultUrl) {
        Object.keys(formData).forEach((item) => {
            resultUrl = resultUrl
                .split(`{${item}}`)
                .join(encodeURIComponent(formData[item]));
        });
        goto(resultUrl);
    }
};

const _v_ = {
    required: ($el) => $el.attr('required') && !$el.val(),
    email: ($el) =>
        $el.attr('type') === 'email' && !REGEXP.EMAIL.test($el.val()),
    pattern: ($el) => {
        const pattern = $el.attr('data-pattern');
        if (!pattern) {
            return false;
        }
        const regexp = new RegExp(pattern, 'gi');
        return !$el.val().match(regexp);
    }
};

const markLoading = ($form) => {
    $form.addClass(submittingClass);
    if (typeof $form.attr('data-no-loading-status') === 'undefined') {
        updateStatus($form, 'loading');
    }
};

const validateForm = (e) => {
    const $form = $(e.target);
    const fields = $form.get(0);
    const errors = {};

    // Validation and collecting the data
    for (let i = 0; i < fields.length; i++) {
        const $field = $(fields[i]);
        const type = $field.attr('type');
        const name = $field.attr('name');

        if (skipFormDataTypes.indexOf(type) !== -1) {
            continue;
        }

        if (_v_.required($field)) {
            errors[name] = 'required';
        }

        switch (type) {
            case 'email':
                if (_v_.email($field)) {
                    errors[name] = 'Wrong email';
                }
                break;
        }
    }

    if (Object.keys(errors).length) {
        e.preventDefault();
        showErrors($form, errors);
        return false;
    }
    return true;
};

const getFormData = ($form) => {
    const formData = {};
    const fields = $form.get(0);

    // Validation and collecting the data
    for (let i = 0; i < fields.length; i++) {
        const $field = $(fields[i]);
        const type = $field.attr('type');
        const name = $field.attr('name');

        if (skipFormDataTypes.indexOf(type) !== -1) {
            continue;
        }

        if (type === 'checkbox') {
            formData[name] = $field[0].checked;
        } else {
            if (name === 'os') {
                formData[name] = os();
            } else {
                formData[name] = $field.val();
            }
        }
    }
    return formData;
};

const sendRequest = (e) => {
    const $form = $(e.target);

    const formData = getFormData($form);

    let endpoint = $form.attr(ajaxAttr);
    let errorHandler = (xhr, error) => {
        updateStatus($form, '');
    };
    const successHandler = (res) => {
        if (res && res.error) {
            showErrors($form, res.error);
            updateStatus($form, '');
        }
        if (res && res.data && res.data.status) {
            updateShowcases($form, formData);
            updateStatus($form, res.data.status, formData);
        }
    };
    const mcSuccessHandler = (res) => {
        if (res.result !== 'success') {
            if (res.msg.indexOf('is already subscribed') !== -1) {
                updateShowcases($form, formData);
                updateStatus($form, 'success', formData);
            } else {
                alert(res.msg);
            }
        } else {
            updateShowcases($form, formData);
            updateStatus($form, res.result, formData);
        }
    };
    const mcErrorHandler = (err) => {
        var errMsg =
            'Could not connect to the registration server. Please try again later.';
        console.error(errMsg);
        alert(errMsg);
    };

    const mcListType = $form.attr('data-mailchimp-list');
    if (!endpoint && !mcListType) {
        return;
    }

    const method =
        $form.attr('data-ajax-method') || $form.attr('method') || 'get';

    const headers = (function () {
        try {
            return $form.attr('data-ajax-headers')
                ? JSON.parse($form.attr('data-ajax-headers'))
                : {};
        } catch (e) {
            return {};
        }
    })();

    // Check if this is mailchimp form
    let mcData = null;
    if (mcListType) {
        mcData = mailchimp.data(formData, mcListType);
    }

    const ajaxConfig = {
        type: method,
        url: endpoint,
        data: formData,
        headers: headers,
        cache: false,
        dataType: 'json',
        error: errorHandler,
        success: successHandler,
        complete: () => {
            $form.removeClass(submittingClass);
        }
    };

    if (mcListType) {
        ajaxConfig.url = mcData.action;
        ajaxConfig.data = mcData.formData;
        ajaxConfig.contentType = 'application/json; charset=utf-8';
        ajaxConfig.error = mcErrorHandler;
        ajaxConfig.success = mcSuccessHandler;
    }

    // Show spinner once form submitted
    markLoading($form);

    e.preventDefault();
    $.ajax(ajaxConfig);
};

export const initialise = () => {
    const $doc = $(document);

    // Validation on blur/input
    const formInputActivateEvent = 'focus keydown';
    const formInputBlurEvent = 'blur';
    $doc.off(formInputActivateEvent, formInput)
        .on(formInputActivateEvent, formInput, (e) => {
            $(e.target).closest(formGroup).removeClass(hasError);
        })
        .off(formInputBlurEvent, formInput)
        .on(formInputBlurEvent, formInput, (e) => {
            const $el = $(e.target);
            if (_v_.required($el) || _v_.email($el) || _v_.pattern($el)) {
                showErrors(
                    $el.closest('form'),
                    { [$el.attr('name')]: 'invalid' },
                    { noShake: true }
                );
            }
        });

    const resetTrigger = '[data-trigger="reset"]';
    $doc.off('click', resetTrigger).on('click', resetTrigger, (e) => {
        e.preventDefault();
        const $container = $(e.target).closest(`[${statusAttr}]`);
        $container.attr(statusAttr, '');
        $container.find('form').get(0).reset();
    });

    $doc.off(submitEvent, formsSelector).on(submitEvent, formsSelector, (e) => {
        const $form = $(e.target);
        if ($form.hasClass(submittingClass)) {
            e.preventDefault();
            return;
        }

        const isValid = validateForm(e);
        if (!isValid) {
            return;
        }

        markLoading($form);

        startCaptcha(e, () => sendRequest(e));
    });
};
