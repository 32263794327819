let __toInit = [];

export const toInit = (func) => {
    if (Array.isArray(func)) {
        __toInit = __toInit.concat(func);
    } else {
        __toInit.push(func);
    }
};

export const init = () => {
    __toInit.forEach((initFunction) => initFunction());
};
