import DomSpy from '../../package/dom-spy';
import { getUrlParam } from '../../helpers/url';
import { waitFor } from '../../helpers/waitFor';
import { trackEvent } from './common';
import { openModal } from '../modal-dialogs';
import cookie from '../../helpers/cookie';

const $byId = (id) => document.getElementById(id);

const loadPaddle = () => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://cdn.paddle.com/paddle/paddle.js';
    document.querySelector('head').appendChild(scriptElement);
};

const getOrderDetails = (checkoutId, orderId, productName, updatedSubscription) => {
    const Paddle = window.Paddle;
    
    if( window.location.hostname !== 'imazing.com' ) {
        Paddle.Environment.set('sandbox');
    }
    
    const thankyouWithLicenseCode = $byId('thank-you-with-license-code');
    const thankyouWithoutLicenseCode = $byId('thank-you-without-license-code');
    const container = $byId('paddle-checkout-license-code');
    const licenseCodesAreaContainer = $byId('license-codes-area');
    const licenseCodesContainer = $byId('license-codes');

    // Subscription order details returned by the Paddle Checkout API are the ones for the first/initial transaction when the Charge API is used, not the transaction that has been just done so we need to build the receipt url and order id with parameters passed to the thank-you page

    Paddle.Order.details(checkoutId, (data) => {

        // Retrieve license codes, if passed to the thank-you page (passed for initial purchases only)
        const code = getUrlParam('code');
        if(code) {
            licenseCodesAreaContainer.classList.remove('hidden');
            licenseCodesContainer.innerHTML = `<div class="activation-code">${code
                    .split('\n')
                    .join('<br />')}</div>`
        }            

        $byId('paddle-checkout-license-type').innerHTML = (updatedSubscription) ? productName : data.checkout.title;
        $byId('paddle-checkout-email').innerHTML = data.order.customer.email;
        $byId('paddle-checkout-email2').innerHTML = data.order.customer.email;

        // Build receipt url for subscription updates
        const receiptUrl = `https://my.paddle.com/invoice/${orderId}/${checkoutId}`
        
        // Order ID
        const orderIDEl = $byId('paddle-checkout-order-id');
        orderIDEl.innerHTML = (updatedSubscription) ? orderId : data.order.order_id;
        
        // Order Link
        const orderLinkEl = $byId('paddle-checkout-order-link');
        orderLinkEl.href = (updatedSubscription) ? receiptUrl : data.order.receipt_url;
        orderLinkEl.setAttribute('target', '_blank');

        // Activation button
        if(code) {
            const activationButton = $byId('paddle-checkout-automatic-activation');
            activationButton.href = 'imz://activate?key=' + code;
            activationButton.setAttribute('target', '_blank');
            
            thankyouWithLicenseCode.classList.remove('hidden');
        } else {
            thankyouWithoutLicenseCode.classList.remove('hidden');
        }

        // Show HTML container
        container.classList.remove('hidden');
    });
};

new DomSpy('#paddle-checkout-license-code', {
    attached(el) {
        const checkoutId = getUrlParam('checkout');
        const orderId = getUrlParam('pi');
        const productName = getUrlParam('pn');
        const updateSubscription = getUrlParam('updated_subscription') === '1';
        
        if (!checkoutId) {
            return;
        }

        if (!window.Paddle) {
            loadPaddle();
        }

        waitFor(() => window.Paddle, () => getOrderDetails(checkoutId, orderId, productName, updateSubscription));
    }
});
