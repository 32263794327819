import { REGEXP, API } from '../config';
import { getFormData } from '../helpers/form';
import { startCaptcha } from '../helpers/recaptcha';

const form = '#lost-activation-form';
const emailSelector = `${form} [name="email"]`;
const productSelector = `${form} [name="product"]`;
const productsContainer = '#lost-activation-products-container';

const formGroup = '.form-group';
const submitEvent = 'submit';
const hasError = 'has-error';
const invalidClass = 'invalid';

const showValidateError = (cfg) => {
    if (cfg.$form) {
        setTimeout(() => {
            cfg.$form.removeClass(invalidClass);
            cfg.$form.width();
            cfg.$form.addClass(invalidClass);
        }, 0);
    }

    if (cfg.$email) {
        cfg.$email.closest(formGroup).addClass(hasError);
    }
};

const formStatus = {
    get $container() {
        if (!formStatus.__$container) {
            formStatus.__$container = $(
                '[data-container-for="license-retrieval"]'
            );
        }
        return formStatus.__$container;
    },
    reset() {
        formStatus.$container.removeAttr('data-status');
        $(emailSelector).val('');
    },
    set(status) {
        formStatus.$container.attr('data-status', status);
    }
};

const sendRequest = (e) => {
    const formData = getFormData(e.target);
    $.ajax({
        type: 'post',
        url: API.RESTORE_LICENSE,
        data: formData,
        cache: false,
        dataType: 'json',
        success: (response) => {
            if (response.data === 'success') {
                $('#lost-activation-user-email').html(formData.email);
                formStatus.set('success');
                return;
            }
            if (response.data === 'fail') {
                formStatus.set('fail');
                return;
            }

            showValidateError({
                $form: $form
            });
        },
        complete: () => {
            $(e.target).removeClass('submitting');
        }
    });
};

export const initialise = () => {
    const $doc = $(document);
    const $formContainer = $('[data-container-for="license-retrieval"]');

    const tryAgainSelector = '#lost-activation-try-again';
    const tryAgainEvent = 'click';
    $doc.off(tryAgainEvent, tryAgainSelector).on(
        tryAgainEvent,
        tryAgainSelector,
        () => {
            $formContainer.removeAttr('data-status');
            $(emailSelector).val('');
        }
    );

    const emailActivateEvent = 'focus keydown';
    const emailBlurEvent = 'blur';
    $doc.off(emailActivateEvent, emailSelector)
        .on(emailActivateEvent, emailSelector, (e) => {
            const $email = $(emailSelector);
            $email.closest(formGroup).removeClass(hasError);
        })
        .off(emailBlurEvent, emailSelector)
        .on(emailBlurEvent, emailSelector, (e) => {
            const $email = $(emailSelector);
            if (
                $(e.relatedTarget).attr('type') !== 'submit' &&
                !REGEXP.EMAIL.test($email.val())
            ) {
                showValidateError({
                    $email: $email
                });
            }
        });

    $doc.off('change', productSelector).on('change', productSelector, (e) => {
        $(`[data-product-value]`).removeClass('checked');
        $(`[data-product-value="${e.target.value}"]`).addClass('checked');
        $(productsContainer).tooltip('hide');
    });

    $doc.off(submitEvent, form).on(submitEvent, form, (e) => {
        e.preventDefault();
        const $form = $(e.target);
        if ($form.hasClass('submitting')) {
            return;
        }
        const formData = getFormData(e.target);
        let error = false;

        if (!REGEXP.EMAIL.test(formData.email)) {
            // Email not valid
            showValidateError({
                $form: $form,
                $email: $form.find('[name="email"]')
            });
            error = true;
        }
        // Field `product` is not used anymore
        //
        // if (!formData.product) {
        //     $(productsContainer).tooltip('show');
        //     error = true;
        // }

        if (error) {
            return;
        }

        $form.addClass('submitting');
        startCaptcha(e, () => sendRequest(e));
    });
};
