import { OS } from '../config';

export const os = () => {
    let result = '';

    const platform = window.navigator.platform.toLowerCase();

    if (platform.indexOf('win') > -1) {
        result = OS.WINDOWS;
    } else if (platform.indexOf('mac') > -1) {
        result = OS.MAC;
    } else {
        // Default to mac if we detect something else (e.g. Android)
        result = OS.MAC;
    }

    return result;
};

export const isMobile = () =>
    !!('ontouchstart' in window || window.navigator.maxTouchPoints);
