import { computePosition, shift, autoUpdate } from '@floating-ui/dom';
import anime from 'animejs/lib/anime.es.js';

const initialisePopper = () => {
    const allMenuItems = document.querySelectorAll(
        '.header .menu-selector > ul > li'
    );

    allMenuItems.forEach((menuItem) => {
        const header = document.querySelector('.header');
        const menuContents = menuItem.querySelector('.menu-contents');

        if (menuContents) {
            autoUpdate(menuItem, menuContents, () => {
                computePosition(menuItem, menuContents, {
                    placement: 'bottom-start',
                    middleware: [
                        shift({
                            boundary: header,
                            padding: { right: 20 }
                        })
                    ]
                }).then(({ x }) => {
                    const left = menuItem.classList.contains(
                        'header-no-float-layout'
                    )
                        ? 20
                        : x;

                    // This is an absolutely horrible set of hacks to fix https://app.asana.com/0/106778566254020/1204398420828687
                    if (
                        document.querySelector('html').getAttribute('dir') ===
                        'rtl'
                    ) {
                        if (
                            menuItem.classList.contains(
                                'header-navigation-lang'
                            )
                        ) {
                            menuContents.style.left = `20px`;
                        } else {
                            menuContents.style.right = `${left}px`;
                            menuContents.style.width = 'max-content';
                        }
                    } else {
                        menuContents.style.left = `${left}px`;
                    }
                });
            });
        }
    });
};

const initialiseHeaderRolledOver = () => {
    const animations = new WeakMap();

    const allMenuItems = Array.from(
        document.querySelectorAll('.header .menu-selector > ul > li')
    );

    const openMenuItem = (menuItem) => {
        stopAnyRunningAnimation(menuItem);

        // Make a new animation to open the menu
        const animation = anime({
            targets: menuItem.querySelector('.menu-contents'),
            translateY: 13,
            opacity: 1,
            duration: 400,
            easing: 'easeOutQuint',
            // Add the menu item to the DOM at the start of the animation
            begin: (_) => menuItem.classList.add('menu-open')
        });

        animations.set(menuItem, animation);
    };

    const closeMenuItem = (menuItem) => {
        menuItem.classList.remove('selected');

        stopAnyRunningAnimation(menuItem);

        // Make a new animation to close the menu
        const animation = anime({
            targets: menuItem.querySelector('.menu-contents'),
            translateY: 0,
            opacity: 0,
            duration: 400,
            easing: 'easeOutQuint',
            // Remove the menu item from the DOM at the end of the animation
            complete: (_) => menuItem.classList.remove('menu-open')
        });

        animations.set(menuItem, animation);
    };

    const stopAnyRunningAnimation = (menuItem) => {
        const menuContents = menuItem.querySelector('.menu-contents');
        const runningAnimation = animations.get(menuItem);

        if (runningAnimation) {
            runningAnimation.pause();
            runningAnimation.remove(menuContents);
        }
    };

    allMenuItems.forEach((menuItem) => {
        menuItem.querySelector('.item').addEventListener('mouseenter', (e) => {
            const menuItem = e.currentTarget.parentNode;
            menuItem.classList.add('selected');
            // Close any open menus
            allMenuItems.filter((i) => i !== menuItem).forEach(closeMenuItem);
            // Open this menu
            openMenuItem(menuItem);
        });
    });

    const menuSelectors = document.querySelectorAll('.header .menu-selector');
    menuSelectors.forEach((menuSelector) => {
        menuSelector.addEventListener('mouseleave', (e) => {
            const rect = menuSelector.getBoundingClientRect();

            if (e.y >= rect.height) {
                allMenuItems.forEach(closeMenuItem);
            }
        });
    });

    // If the mouse leaves the header in any direction apart from down, close the menus
    const header = document.querySelector('.header');
    header.addEventListener('mouseleave', (e) => {
        const headerRect = header.getBoundingClientRect();
        if (e.y < headerRect.y + headerRect.height) {
            allMenuItems.forEach(closeMenuItem);
        }
    });

    // For development
    // openMenuItem(allMenuItems[1]);
};

const initialiseClicked = () => {
    const allMenuItems = document.querySelectorAll('.menu-selector > ul > li');

    allMenuItems.forEach((menuItem) => {
        menuItem.querySelector('.item').addEventListener('click', (e) => {
            allMenuItems.forEach((menuItem) => {
                menuItem.classList.toggle(
                    'clicked',
                    menuItem.querySelector('.item') === e.currentTarget &&
                        !menuItem.classList.contains('clicked')
                );
            });
        });
    });
};

/** In mobile view the `Menu` button should open the navigation.  This also implements
 * a very fiddly SVG animation.
 */
const initialiseHeaderMenuButtons = () => {
    const header = document.querySelector('.header');
    const menuButtons = Array.from(document.querySelectorAll('.btn-menu'));

    let animations = new Set();

    const openMobileMenu = (menuOrLang) => {
        header.classList.add('open', `open-${menuOrLang}`);

        animations.forEach((runningAnimation) => {
            runningAnimation.pause();
            runningAnimation.remove(runningAnimation.targets);
        });

        switch (menuOrLang) {
            case 'menu':
                anime({
                    targets: '.btn-menu svg',
                    top: 5,
                    duration: 300,
                    easing: 'easeInOutQuad',
                    autoplay: true,
                    begin: (a) => animations.add(a),
                    complete: (a) => animations.delete(a)
                });

                anime({
                    targets: '.btn-menu svg rect:nth-child(1)',
                    opacity: 1,
                    rotateZ: -45,
                    x: 4,
                    y: 0,
                    duration: 300,
                    easing: 'easeInOutQuad',
                    autoplay: true,
                    begin: (a) => animations.add(a),
                    complete: (a) => animations.delete(a)
                });

                anime({
                    targets: '.btn-menu svg rect:nth-child(2)',
                    opacity: 1,
                    x: 1,
                    y: 11,
                    rotateZ: 45,
                    duration: 300,
                    easing: 'easeInOutQuad',
                    autoplay: true,
                    begin: (a) => animations.add(a),
                    complete: (a) => animations.delete(a)
                });
                break;
            case 'lang':
                document.querySelector(
                    '.header-navigation-lang .menu-contents'
                ).style.opacity = 1;
                break;
        }
    };

    const closeMobileMenu = () => {
        header.classList.remove('open', 'open-lang', 'open-menu');

        animations.forEach((runningAnimation) => {
            runningAnimation.pause();
            runningAnimation.remove(runningAnimation.targets);
        });

        anime({
            targets: '.btn-menu svg',
            top: 2,
            duration: 300,
            easing: 'easeInOutQuad',
            autoplay: true,
            begin: (a) => animations.add(a),
            complete: (a) => animations.delete(a)
        });

        anime({
            targets: '.btn-menu svg rect:nth-child(1)',
            opacity: 0.7,
            x: 0,
            y: 4,
            rotateZ: 0,
            duration: 300,
            easing: 'easeInOutQuad',
            autoplay: true,
            begin: (a) => animations.add(a),
            complete: (a) => animations.delete(a)
        });

        anime({
            targets: '.btn-menu svg rect:nth-child(2)',
            opacity: 0.7,
            x: 0,
            y: 10,
            rotateZ: 0,
            duration: 300,
            easing: 'easeInOutQuad',
            autoplay: true,
            begin: (a) => animations.add(a),
            complete: (a) => animations.delete(a)
        });
    };

    menuButtons.forEach((menuButton) =>
        menuButton.addEventListener('click', () => {
            const target = menuButton.getAttribute('data-target');

            if (!header.classList.contains('open')) {
                openMobileMenu(target);
            } else {
                closeMobileMenu();
            }
        })
    );

    window.addEventListener('click', (e) => {
        const rectHeights = Array.from(
            document.querySelectorAll('.header .menu-selector')
        ).map((menuSelector) => menuSelector.getBoundingClientRect().height);

        if (e.y > Math.max(...rectHeights)) {
            closeMobileMenu();
        }
    });
};

export const initialise = () => {
    initialisePopper();
    initialiseHeaderRolledOver();
    initialiseClicked();
    initialiseHeaderMenuButtons();
};
