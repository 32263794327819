// DEMO: http://scrollmagic.io/examples/basic/scene_manipulation.html

const scenes = [];
const animationScenes = [];
let animationController;

scenes.push({
    shouldInit: () => {
        return $('#covers-trigger').length > 0;
    },
    init: () => {
        const $albums = $('.covers .album[data-index]');
        const images = Array.from({ length: $albums.length }).map(
            (_, idx) => idx + 1
        );
        const options = {};
        $albums.each((idx, el) => {
            const { index, scale } = el.dataset;
            options[index] = {
                marginLeft: 0,
                marginTop: 260,
                rotation:
                    index === 7
                        ? 0
                        : (Math.random() > 0.5 ? 1 : -1) * Math.random() * 10,
                scale: scale || 1
            };
        });

        // Albums
        images.forEach((val) => {
            const album = TweenMax.to(
                `.covers .album[data-index="${val}"]`,
                1,
                options[val]
            );
            animationScenes.push(
                new ScrollMagic.Scene({
                    triggerElement: '#covers-trigger',
                    triggerHook:
                        0.2 +
                        (Math.random() > 0.5 ? 1 : -1) * Math.random() * 0.03,
                    duration:
                        400 +
                        (Math.random() > 0.5 ? 1 : -1) * Math.random() * 50
                }).setTween(album)
            );
        });
        // Arrow
        {
            const arrow = TweenMax.to('.covers-arrow', 1, {
                opacity: 1,
                height: 100
            });
            animationScenes.push(
                new ScrollMagic.Scene({
                    triggerElement: '#covers-trigger',
                    triggerHook: 0.6,
                    duration: 200,
                    offset: 150
                }).setTween(arrow)
            );
        }
    }
});

const contactsTrigger = '#contacts-trigger';
scenes.push({
    shouldInit: () => {
        return $(contactsTrigger).length > 0;
    },
    init: () => {
        const $contacts = $('.contacts .persona[data-index]');
        const images = Array.from({ length: $contacts.length }).map(
            (_, idx) => idx + 1
        );
        const options = {};
        $contacts.each((idx, el) => {
            const { index } = el.dataset;
            options[index] = {
                marginLeft: 0,
                marginTop: 270
            };
        });

        // Albums
        images.forEach((val) => {
            const scene = TweenMax.to(
                `.contacts .persona[data-index="${val}"]`,
                1,
                options[val]
            );
            animationScenes.push(
                new ScrollMagic.Scene({
                    triggerElement: contactsTrigger,
                    triggerHook:
                        0.1 +
                        (Math.random() > 0.5 ? 1 : -1) * Math.random() * 0.03,
                    duration:
                        400 +
                        (Math.random() > 0.5 ? 1 : -1) * Math.random() * 50
                }).setTween(scene)
            );
        });

        // Albums
        images.forEach((val) => {
            const scene = TweenMax.to(
                `.contacts .persona[data-index="${val}"]`,
                1,
                {
                    opacity: 0,
                    scale: 0.5
                }
            );
            animationScenes.push(
                new ScrollMagic.Scene({
                    triggerElement: contactsTrigger,
                    triggerHook: 0.0,
                    duration:
                        500 +
                        (Math.random() > 0.5 ? 1 : -1) * Math.random() * 50
                }).setTween(scene)
            );
        });

        // Arrow
        const arrow = TweenMax.to('.contacts-arrow', 1, {
            opacity: 1,
            height: 100
        });
        animationScenes.push(
            new ScrollMagic.Scene({
                triggerElement: contactsTrigger,
                triggerHook: 0.65,
                duration: 200,
                offset: 150
            }).setTween(arrow)
        );

        // vCard
        const scene = TweenMax.to('.contacts .icon-vcard', 1, { opacity: 1 });
        animationScenes.push(
            new ScrollMagic.Scene({
                triggerElement: contactsTrigger,
                triggerHook: 0.7,
                duration: 400,
                offset: 300
            }).setTween(scene)
        );
    }
});

/**
 * Initialize on App load new state (window load new addr)
 */
export const initialise = () => {
    if (animationController) {
        animationController.destroy();
    }

    animationController = new ScrollMagic.Controller({
        loglevel: 2,
        addIndicators: false
    });

    animationScenes.length = 0;

    scenes.forEach((scene) => {
        if (scene.shouldInit()) {
            scene.init();
        }
    });

    animationScenes.forEach((scene) => scene.addTo(animationController));
};
