const { detect } = require('detect-browser');
let detectedBrowser;

export function getBrowser() {
    if (!detectedBrowser) {
        detectedBrowser = detect();
    }

    return detectedBrowser && detectedBrowser.name;
}
