const magnifierSelector = '.field-magnifier';

const formGroup = '.form-group';
const formControl = '.form-control';

export const initialise = () => {
    $(document)
        .off('click', magnifierSelector)
        .on('click', magnifierSelector, (e) => {
            $(e.target)
                .closest(formGroup)
                .find(formControl)
                .focus();
        });
};
