const NAMESPACE = 'features-tabs';
const event = `resize.${NAMESPACE}`;

const width = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200
};

const $elsH = {};

const styles = {};

const getStylesFor = (size) => {
    styles[size] = [];

    Object.keys(width).forEach((key) => ($elsH[key] = $(`[data-h-${key}]`)));

    $elsH[size].each((index) => {
        const h = $elsH[size].eq(index).data(`h-${size}`);
        styles[size].push(`[data-h-${size}="${h}"] { height: ${h} }`);
    });
};

let latestSize = '';
const setStyles = (size) => {
    if (latestSize === size) {
        return;
    }

    $('html').attr('data-size', size);
    const $newStyle = $(`<style data-for="${size}"></style>`).append(
        styles[size].join('')
    );
    $('style[data-for]').remove();
    $('head').append($newStyle);
    latestSize = size;
};

export const getSizeShortname = () => {
    const w = document.body.offsetWidth;
    if (w >= width.lg) {
        return 'lg';
    }
    if (w >= width.md) {
        return 'md';
    }
    if (w >= width.sm) {
        return 'sm';
    }
    return 'xs';
};

const updateStyles = () => {
    const size = getSizeShortname();
    setStyles(size);
};

export const initialise = () => {
    latestSize = '';
    // Handle responsive height
    Object.keys(width).forEach((size) => getStylesFor(size));

    updateStyles();
    $(window)
        .off(event)
        .on(event, updateStyles);
};
