const defaultSkipTypes = ['submit', 'reset', 'button'];

export const getFormData = (formElement, options = {}) => {
    const { skipTypes = defaultSkipTypes } = options;
    const fields = [...formElement.elements];

    const formData = fields.reduce((formData, field) => {
        const type = field.getAttribute('type');
        const name = field.getAttribute('name');
        if (skipTypes.includes(type)) {
            return formData;
        }

        if (type === 'radio') {
            if (field.checked) {
                formData[name] = field.value;
            }
            return formData;
        }

        if (type === 'checkbox') {
            formData[name] = field.checked;
            return formData;
        }

        formData[name] = field.value;

        return formData;
    }, {});

    return formData;
};
