import cookie from '../helpers/cookie';
import { getUrlParam } from '../helpers/url';
import { COUPON_COOKIE_TTL } from '../config';

export const updateCouponCookie = () => {
    const coupon = getUrlParam('coupon');

    if (!coupon) {
        return;
    }

    cookie.set('coupon', coupon, COUPON_COOKIE_TTL);

    // This will remove current existing close cookie
    cookie.remove('banner_coupon');
};

export const getSavedCoupon = () => cookie.get('coupon');
